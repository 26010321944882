<template>
    <div class="record-con">
        <div class="table-thead flex">
            <div>{{ $t('record.desc16') }}</div>
            <div>{{ $t('record.desc5') }}</div>
            <div>{{ $t('transfer.desc4') }}</div>
            <div>{{ $t('transfer.desc16') }}</div>
            <div>{{ $t('transfer.desc2') }}</div>
            <div>{{ $t('transfer.desc17') }}</div>
            <div>{{ $t('record.desc12') }}</div>
        </div>
        <div class="table-tbody">
            <van-list v-model:loading="loading" :finished="finished" :loading-text="$t('quotes.desc20')" @load="onLoad">
                <van-cell v-for="item in list" :key="item">
                    <div class="list-con">
                        <div class="list-info flex">
                            <div>{{ $t('record.desc16') }}</div>
                            <p class="copyinfo flex">{{ dealAddress(item.orderNo) }} <img
                                    src="../../public/images/new/copy.png" alt="" @click="copy($event, item.orderNo)" /></p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc5') }}</div>
                            <p>{{ common.formatDate(item.transferTime) }}</p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc17') }}</div>
                            <p>{{ selectTypeName(item.payAccountType) }}</p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc18') }}</div>
                            <p>{{ selectTypeName(item.returnAccountType) }}</p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc19') }}</div>
                            <p class="flex">
                                <img :src="item.payCoinIcon" alt="" /> {{ item.payCoinName }}
                            </p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc20') }}</div>
                            <p>{{ item.payAmount }} {{ item.payCoinName }}</p>
                        </div>
                        <div class="list-info flex">
                            <div>{{ $t('record.desc21') }}</div>
                            <p>{{ $t('transfer.desc18') }}</p>
                        </div>
                    </div>
                </van-cell>
            </van-list>
        </div>
        <div class="no-data" v-if="showNull">
            <img src="../../public/images/no-data.png" alt="">
            <span>{{ $t('record.desc13') }}</span>
        </div>
    </div>
</template>

<script>
import Clipboard from '@/utils/libs/clipboard'
export default {
    props: ['coinId'],
    data() {
        return {
            list: [],
            finished: false,
            loading: false,
            page: 1,
            showNull: false
        }
    },
    methods: {
        selectTypeName(type) {
            switch (type) {
                case 1:
                    return this.$t("asset.desc100");
                case 2:
                    return this.$t("asset.desc101");
                case 3:
                    return this.$t("new.desc9");
                default:
                    break;
            }
        },
        copy(e, text) {
            // @click="copy($event,googleRandom.secretKey)"
            let that = this;
            Clipboard(e, text).then((res) => {
                that.$message.success(this.$t('planet.desc61'))
            });
        },
        dealAddress(str) {
            if (str && str.length > 20) {
                return str.substring(0, 10) + '....' + str.substring(str.length - 10, str.length)
            } else {
                return str
            }
        },
        onLoad() {
            setTimeout(() => {
                this.$post(this.URL.assets.transferRecord, {
                    page: this.page,
                    pageSize: 10,
                    payCoinId: this.coinId
                }).then(res => {
                    if (res.code == 0) {
                        if (this.page == 1) {
                            this.list = res.data.list
                        } else {
                            this.list = this.list.concat(res.data.list)
                        }
                        this.page++;
                        this.loading = false;
                        this.finished = true;
                        if (this.list.length == 0) {
                            this.showNull = true;
                        }
                    }
                })
            }, 500);
        }
    }
}
</script>

<style lang="less" scoped>
.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #A1A1A1;
    }
}

.table-thead {
    padding: 20px 24px;
    background: #181818;

    div {
        flex: 0 0 204px;
        font-size: 13px;
        color: rgba(255, 255, 255, .6);

        &:first-child {
            flex: 0 0 250px;
        }

        &:nth-child(4),
        &:nth-child(3){
            flex: 1;
        }
        &:nth-child(5) {
            flex: 0 0 120px;
        }


        &:nth-child(6) {
            flex: 0 0 200px;
        }

        &:last-child {
            flex: 0 0 80px;
        }
    }
}

.table-tbody {
    ::v-deep .van-list {
        .van-cell {
            background: none;
            padding: 26px 24px;

            &::after {
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }

            .list-con {
                display: flex;

                .list-info {
                    flex: 0 0 204px;
                    font-size: 13px;
                    color: rgba(255, 255, 255, .6);
                    line-height: 18px;

                    p {
                        color: #fff;

                    }

                    img {
                        width: 12px;
                        height: 12px;
                        margin: 3px 0 0 4px;
                        cursor: pointer;
                    }

                    div {
                        display: none;
                    }

                    &:first-child {
                        flex: 0 0 250px;
                    }

                    &:nth-child(4),
                    &:nth-child(3){
                        flex: 1;
                    }
                    &:nth-child(5) {
                        flex: 0 0 120px;

                        img {
                            width: 18px;
                            height: 18px;
                            margin: 0 4px 0 0;
                        }
                    }




                    &:nth-child(6) {
                        flex: 0 0 200px;
                    }

                    &:last-child {
                        flex: 0 0 80px;
                    }
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .table-thead {
        padding: 16px;

        div {
            flex: 1;

            &:first-child {
                flex: 1;
            }

            &:nth-child(2) {
                margin-left: 4px;
            }

            &:nth-child(3) {
                flex: 0 0 100px;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                flex: 0 0 80px;
            }

        }
    }

    .table-tbody {
        ::v-deep .van-list {
            .van-cell {
                padding: 16px;

                .list-con {
                    .list-info {
                        flex: 1;

                        &:first-child {
                            flex: 1;
                        }

                        &:nth-child(2) {
                            margin-left: 4px;
                        }

                        &:nth-child(3) {
                            flex: 0 0 100px;
                        }

                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            flex: 0 0 80px;
                        }


                    }
                }
            }
        }
    }
}</style>