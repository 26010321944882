<template>
    <div class="recharge">

        <div class="h5header">
            <img src="../../public/images/new/backicon.png" alt="" class="return" @click="$router.go(-1)" />
            <div class="name flexcenter">{{ $t('transfer.desc1') }}</div>
            <div class="lang">
                <img src="../../public/images/new/recordicon.png" alt=""
                    @click="$router.push('/transfer/record?id=' + this.coinId)" />
            </div>
        </div>
        <div class="wrap">
            <div class="wrap-title"><span @click="$router.go(-1)" v-if="$route.query.type == 2">{{ $t('asset.desc101')
            }}</span><span @click="$router.go(-1)" v-if="$route.query.type == 3">{{ $t('new.desc9') }}</span> >
                <span>{{
                    $t('transfer.desc1')
                }}</span>
            </div>
            <div class="wrap-con">
                <div class="con-title">{{ $t('transfer.desc2') }}</div>
                <div class="info-select">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <div class="icon-name flex">
                                <img :src="iconItem.payCoinIcon" alt="" />{{ iconItem.payCoinName }}

                            </div>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="select-search">
                                    <div class="name">{{ $t('swap.desc6') }}</div>
                                    <div class="input flex">
                                        <img src="../../public/images/new/searchicon1.png" alt="" />
                                        <input type="text" :placeholder="$t('asset.desc59')" v-model="searchText" />
                                    </div>
                                </div>

                                <el-dropdown-item :command="item.payCoinId" v-for="item in iconList"
                                    v-if="searchText == ''">
                                    <div class="coin-info flex">
                                        <img :src="item.payCoinIcon" alt="" />{{ item.payCoinName }}
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item :command="item.payCoinId" v-for="item in coinShowList" v-else>
                                    <div class="coin-info flex">
                                        <img :src="item.payCoinIcon" alt="" />{{ item.payCoinName }}
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <div class="select-down"><img src="../../public/images/select-down.png" alt=""></div>

                </div>
                <div class="con-title">{{ $t('transfer.desc3') }}</div>
                <div class="account flex">
                    <div class="account-left">
                        <div class="left-info flex">
                            <img src="../../public/images/asset/typeicon.png" alt="" />
                            <div>{{ $t('transfer.desc4') }}</div>
                        </div>
                        <div class="left-icon">
                            <img src="../../public/images/asset/typeicon3.png" alt="" />
                        </div>
                        <div class="left-info flex">
                            <img src="../../public/images/asset/typeicon2.png" alt="" />
                            <div>{{ $t('transfer.desc5') }}</div>
                        </div>
                    </div>
                    <div class="account-center">
                        <div class="center-type">
                            <el-dropdown @command="handleCommand1" popper-class="modalType">
                                <span class="el-dropdown-link">
                                    <div class="icon-name flex">
                                        {{ selectTypeName(payAccountType) }}
                                    </div>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item :command="item.payAccountType" v-for="item in typeList"
                                            v-show="item.payAccountType == 2">
                                            <div class="coin-info flex">
                                                {{ selectTypeName(item.payAccountType) }}
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <div class="select-down"><img src="../../public/images/select-down.png" alt=""></div>
                        </div>
                        <div class="center-type">
                            <el-dropdown @command="handleCommand2" popper-class="modalType">
                                <span class="el-dropdown-link">
                                    <div class="icon-name flex">
                                        {{ selectTypeName(returnAccountType) }}

                                    </div>
                                </span>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item :command="item.returnAccountType" v-for="item in typeList"
                                            v-show="item.payAccountType == 3">
                                            <div class="coin-info flex">
                                                {{ selectTypeName(item.returnAccountType) }}
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <div class="select-down"><img src="../../public/images/select-down.png" alt=""></div>
                        </div>
                    </div>
                    <div class="account-switch" @click="handlerSwitch">
                        <img src="../../public/images/asset/typeicon4.png" :class="{ grey: typeList.length == 1 }" alt="">
                    </div>
                </div>
                <div class="con-title">{{ $t('transfer.desc6') }}</div>
                <div class="con-amount flex">
                    <input type="text" placeholder="" v-model="amount" @change="onChange"
                        oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')" />
                    <div>{{ iconItem.payCoinName }}</div>
                    <p @click="handlerAll">{{ $t('transfer.desc7') }}</p>
                </div>
                <div class="price-tips">
                    {{ $t('transfer.desc8') }} <span>{{ payInfo.amount }} {{ iconItem.payCoinName }}</span>
                </div>
                <div class="con-btn flexcenter" :class="{ grey: amount == '' }" v-loading="loading" @click="handlerSubmit">
                    {{
                        $t('transfer.desc9') }}</div>
            </div>
            <div class="wrap-record">
                <div class="record-title flex">
                    <div>{{ $t('transfer.desc10') }}</div>

                </div>
                <Record :coinId="coinId" v-if="showRecord" />
            </div>
        </div>
    </div>
</template>
  
<script>
import Record from './transferrecord.vue'
export default {
    components: {
        Record
    },
    data() {
        return {
            amount: '',
            iconItem: {},
            iconList: [],
            coinId: 1,
            coinInfo: {},
            searchText: '',
            coinShowList: [],
            payAccountType: '',
            returnAccountType: '',
            loading: false,
            configId: '',
            typeList: [],
            payInfo: {},
            showRecord: true
        }
    },
    watch: {
        searchText(val) {
            this.selectCoin(val)
        },
    },
    mounted() {
        this.coinId = this.$route.query.coinId;
        this.payAccountType = this.$route.query.type;
        this.$post(this.URL.assets.transferConfigList, {
        }).then(res => {
            if (res.code == 0) {

                this.iconList = res.data;
                let info = this.iconList.find(item => item.payCoinId == this.coinId)
                this.iconItem = info;
            }
        })
        this.getConfig()

    },
    methods: {
        onChange(val) {
            this.amount = this.common.cutXiaoNum1(this.amount);
            if (this.amount > parseFloat(this.payInfo.amount)) {
                this.amount = this.common.cutXiaoNum1(this.payInfo.amount);
            }
        },
        handlerSwitch() {

            if (this.typeList.length > 1) {
                this.payAccountType = this.returnAccountType;
                this.getConfig();
            }


        },
        getConfig() {
            this.$post(this.URL.assets.transferConfig, {
                payCoinId: this.coinId,
                payAccountType: this.payAccountType
            }).then(res => {
                if (res.code == 0) {
                    this.payInfo = res.data;
                    let arr = []
                    res.data.assetTransferConfigVos.forEach(e => {
                        // let time = this.common.gettime(new Date()),
                        //     startTime = new Date(e.startTime),
                        //     endtime = new Date(e.endTime);
                        let todaylen = new Date().getTime(),
                            statrlen = e.startTimes,
                            endlen = e.endTimes;
                            console.log(todaylen,endlen,statrlen)
                        if (todaylen > statrlen && todaylen < endlen) {
                            e.statBtn = 1;
                            arr.push(e)
                        }



                    })
                    this.typeList = arr;
                    let info = this.typeList.find(item => item.payAccountType == this.payAccountType)
                    this.payAccountType = info.payAccountType;
                    this.returnAccountType = info.returnAccountType;
                    this.configId = info.configId;

                }
            })
        },
        selectTypeName(type) {
            switch (type) {
                case 1:
                    return this.$t("asset.desc100");
                case 2:
                    return this.$t("asset.desc101");
                case 3:
                    return this.$t("new.desc9");
                default:
                    break;
            }
        },
        handlerSubmit() {
            if (this.amount == '') {
                this.$message.error(this.$t('transfer.desc15'));
                return;
            }
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.$post(this.URL.assets.transfer, {
                configId: this.configId,
                amount: this.amount
            }).then(res => {

                if (res.code == 0) {
                    this.$message.success(this.$t('transfer.desc18'));
                    this.amount = '';
                    this.getConfig();
                    this.showRecord = false;
                    this.loading=false;
                    setTimeout(() => {
                        this.showRecord = true;
                    }, 100);
                } else {
                    this.loading = false;
                    this.$message.error(res.message)
                }
            })
        },
        handlerAll() {
            this.amount = this.payInfo.amount
        },
        handleCommand(val) {
            if (val != this.coinId) {
                this.amount = '';
                this.coinId = val;
                let info = this.iconList.find(item => item.payCoinId == this.coinId);
                this.iconItem = info;
                this.payAccountType = info.payAccountType;
                this.getConfig();
            }
        },
        handleCommand1(val) {
            this.payAccountType = val;
            let info = this.typeList.find(item => item.payAccountType == val);
            this.returnAccountType = info.returnAccountType;

        },
        handleCommand2(val) {
            this.returnAccountType = val;
            let info = this.typeList.find(item => item.returnAccountType == val);
            this.payAccountType = info.payAccountType;
        },
        selectCoin(name) {

            if (name) {
                let arr = this.iconList
                let newArr = []
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].payCoinName.toUpperCase().indexOf(name.toUpperCase()) != -1) {
                        newArr.push(arr[i])
                    }
                }
                this.coinShowList = newArr
            } else {
                this.coinShowList = this.iconList
            }
        },

    }

}
</script>
  
<style lang="less" scoped>
.recharge {
    padding: 116px 0 69px;
    width: 100%;
    min-height: 100vh;
    background: url('../../public/images/new/landbg.png') center no-repeat;
    background-size: 100% 100%;

    .wrap {
        width: 1240px;
        margin: 0 auto;

        .wrap-title {
            color: #A0A0A0;
            font-size: 13px;

            span {
                &:first-child {
                    cursor: pointer;
                }

                &:last-child {
                    color: #fff;
                }
            }
        }

        .wrap-con {
            margin: 32px auto 0;
            width: 480px;

            .con-title {
                margin-bottom: 16px;
                font-size: 18px;
                color: #FFFFFF;
            }

            .info-select {
                margin-bottom: 24px;
                position: relative;
                padding: 12px 16px;
                height: 48px;
                border: 1px solid rgba(255, 255, 255, 0.20);
                box-sizing: border-box;
                cursor: pointer;

                .select-down {
                    position: absolute;
                    top: 50%;
                    right: 21px;
                    transform: translateY(-50%);

                    img {
                        width: 10px;
                        height: 6px;
                    }
                }
            }

            .account {
                margin-bottom: 24px;
                padding: 0 16px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                box-sizing: border-box;

                .account-left {
                    flex: 0 0 83px;
                    width: 83px;
                    margin-top: 20px;

                    .left-info {
                        font-size: 16px;
                        color: rgba(255, 255, 255, 0.6);
                        line-height: 18px;

                        img {
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                        }
                    }

                    .left-icon {
                        margin: 15px 0 15px 4px;

                        img {
                            width: 10px;
                            height: 13px;
                        }
                    }
                }

                .account-center {
                    flex: 0 0 294px;
                    width: 294px;

                    .center-type {
                        width: 294px;
                        position: relative;
                        padding: 21px 0;
                        cursor: pointer;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                        &:last-child {
                            border-bottom: 0;
                        }

                        .select-down {
                            position: absolute;
                            top: 50%;
                            right: 21px;
                            transform: translateY(-50%);

                            img {
                                width: 10px;
                                height: 6px;
                            }
                        }
                    }
                }

                .account-switch {
                    flex: 0 0 38px;
                    width: 38px;
                    margin: 40px 0 0 24px;

                    img {
                        width: 38px;
                        height: 38px;
                        cursor: pointer;
                    }
                }
            }

            .con-amount {
                font-size: 16px;
                padding: 16px;
                border: 1px solid rgba(255, 255, 255, 0.2);
                box-sizing: border-box;
                line-height: 16px;

                input {
                    flex: 1;
                    height: 16px;
                    font-size: 16px;
                    color: #fff;
                }

                div {
                    color: #fff;
                }

                p {
                    margin-left: 16px;
                    padding-left: 16px;
                    border-left: 1px solid rgba(255, 255, 255, 0.2);
                    color: #C9FA5B;
                    cursor: pointer;
                }
            }

            .price-tips {
                margin: 14px 0 32px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);

                span {
                    color: #fff;
                }
            }

            .con-btn {
                width: 100%;
                height: 59px;
                background: url('../../public/images/new/btnbg7.png') center no-repeat;
                background-size: 100% 100%;
                font-size: 16px;
                color: #000000;
                cursor: pointer;
            }

            .grey {
                filter: grayscale(1) !important;
            }
        }

        .record-title {
            margin: 40px 0 16px;
            justify-content: space-between;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 23px;

            p {
                font-size: 14px;
                cursor: pointer;

                img {
                    width: 5px;
                    height: 8px;
                    margin: 7px 0 0 6px;
                }
            }
        }
    }
}

.h5header {
    display: none;
}

::v-deep .el-dropdown {
    width: 100%;
    border: 0 !important;

    .el-dropdown-link {
        width: 100%;
        border: 0 !important;

        .icon-name {
            width: 100%;
            font-size: 16px;
            color: #fff;
            line-height: 24px;
            border: 0px solid #eee !important;

            img {
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                margin-right: 8px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}

.coin-info {
    width: 446px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;

    &:hover {
        background: #292929 !important;
    }

    img {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.select-search {
    padding: 0 16px 6px;
    width: 446px;

    .name {
        padding-top: 20px;
        font-size: 18px;
        color: #FFFFFF;
    }

    .input {
        margin-top: 20px;
        width: 100%;
        padding: 13px 12px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-sizing: border-box;

        img {
            flex: 0 0 18px;
            width: 18px;
            height: 18px;
            margin-right: 6px;
        }

        input {
            flex: 1;
            height: 18px;
            font-size: 16px;
            color: #fff;

            &::placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}

@media (max-width:1200px) {
    .recharge {
        padding: 88px 16px 70px;

        .wrap {
            width: 100%;

            .wrap-con {
                width: 100%;
                margin-top: 16px;
                flex-wrap: wrap;

                .account {
                    .account-left {
                        flex: 0 0 72px;
                        width: 72px;
                    }

                    .account-center {
                        flex: 1;
                        width: auto;

                        .center-type {
                            padding: 19px 0;
                            width: 100%;
                        }
                    }

                    .account-switch {
                        flex: 0 0 32px;
                        width: 32px;
                        margin: 40px 0 0 8px;

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }

                .price-tips {
                    margin: 10px 0 32px;
                    text-align: right;
                }

                .con-btn {
                    width: 343px;
                    height: 52px;
                    margin: 0 auto;
                    background: url('../../public/images/asset/btnbg1.png') center no-repeat;
                    background-size: 100% 100%;
                }

            }

        }
    }
}

@media (max-width:767px) {

    .coin-info,
    .select-search {
        width: 100%;
    }

    .h5header {
        position: relative;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 44px;
        background: #000;
        z-index: 888;
        display: block;

        .return {
            position: absolute;
            top: 50%;
            left: 16px;
            width: 14px;
            height: 22px;
            transform: translateY(-50%);
        }

        .name {
            width: 80%;
            margin: 0 auto;
            font-size: 16px;
            color: #fff;
            height: 44px;
        }

        .lang {
            position: absolute;
            top: 50%;
            right: 16px;
            width: 20px;
            height: 20px;
            transform: translateY(-50%);

            img {
                width: 20px;
                height: 20px;
            }
        }

    }

    .recharge {
        padding: 56px 16px;

        .wrap {
            .wrap-title {
                display: none;
            }

            .wrap-con {
                .con-btn {
                    width: 100%;
                    height: 52px;
                    background: url('../../public/images/asset/btnbg1.png') center no-repeat;
                    background-size: 100% 100%;
                }
            }

            .wrap-record {
                display: none;
            }
        }
    }
}
</style>